@import "common/_variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "common/global";

@import url(https://fonts.googleapis.com/css?family=Oxygen:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);

/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin border-radius($pixels) {
    -webkit-border-radius: $pixels;
	-moz-border-radius: $pixels;
	border-radius: $pixels;
}

@mixin line {
	display: inline-block;
	width: $button-size;
	height: $button-size/7;
	background: #fff;
	transition: $transition;
}

/* ==========================================================================
   Common
   ========================================================================== */
html {
	position: relative;
	min-height: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 60px;
}

#banner {
	background: url('../images/banner.jpg') 50% 50% no-repeat;
	background-size: cover;
}

h1,h2,h3,h4,h5,.h1,.h2,.h3,.h4,.h5,button {
	word-break: break-word;
	color: $dark-brown;
	font-family: 'Oxygen', sans-serif;
	margin-bottom: 30px;
}

h1, .h1 { margin-top: 0; }
h2, .h2 { font-size: 26px; }
h3, .h3 { font-size: 22px; }

#content {
	margin-top: 30px;
	margin-bottom: 30px;
}

#footer {
	background: $brown-gray;
	color: #fff;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	padding-top: 20px;
	font-size: 14px;
}

.media {
	padding: 15px 0;

	.media-body, .media-left, .media-right {
		@media (max-width: $screen-sm-max) {
			display: block;
			width: auto !important;
		}
	}
	.media-left {
		padding-right: 20px;

		@media (max-width: $screen-sm-max) {
			margin-bottom: 10px;
			padding-right: 0;
		}
	}
}

#sidebar, #fp-banner {
	img {
		width: 100%;
		height: auto;
		max-width: 360px;
	}
}

/* ==========================================================================
   Navigation
   ========================================================================== */
#primary-navigation {
	background: #fff;	
    min-height: initial;
    margin-bottom: 0;

	.navbar-brand {
		height: initial;
		display: block;
		font-family: 'Oxygen', sans-serif;
		font-weight: bold;
		color: $brown;

		img {
			width: 80px;
		}
	}

	.navbar-toggle {
		margin-top: 42px;
	}

	.navbar-nav {
		margin-top: 36px;

		@media (max-width: $screen-sm-max) {
			margin-top: inherit;
		}

		> li {
			> a {
				margin: 0 5px;
				font-size: 16px;
				border-bottom: 3px solid transparent;
				font-family: 'Oxygen', sans-serif;

				&:hover, &:focus, &:active {
					border-bottom-color: $green;
				}
			}

			&.active {
				> a {
					background-color: transparent;
					border-bottom-color: $green;
				}
			}
		}
	}
}

/* ==========================================================================
   Widgets
   ========================================================================== */
#loans-search {
	background: #ebebeb;
	padding: 20px;
	border-bottom: 4px solid $green;

	.front & {
		width: 50%;
		margin: 50px auto;
		
		background: rgba(255,255,255,.9);

		@media (max-width: $screen-sm-max) {
			width: 80%;
		}

		@media (max-width: 500px) {
			width: 95%;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		[class*='col-md-'] {
			width: 100%;
			text-align: left;
		}

		.btn {
			@extend .btn-lg;
		}
	}

	h3 {
		margin: 0 0 15px;
	}
}

/* ==========================================================================
   Helpers
   ========================================================================== */
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-60 { margin-top: 60px !important; }

.mr-0 { margin-right: 0px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.mr-60 { margin-right: 60px !important; }

.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-60 { margin-bottom: 60px !important; }

.ml-0 { margin-left: 0px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-40 { margin-left: 40px !important; }
.ml-60 { margin-left: 60px !important; }

.pt-15 { padding-top: 15px !important; }
.pl-5 { padding-left: 5px !important; }
.pr-5 { padding-right: 5px !important; }
.p-0 { padding: 0 !important; }

@media only screen and (max-width: $screen-xs-max) {
	#loans-table {
		tr,
	    th,
	    td {
	        display: block;
	    }
	    thead tr {
	        position: absolute;
	        top: -9999px;
	        left: -9999px
	    }
	    tbody {
	    	tr {
		        border: 1px solid #ccc;
		        margin-top: 10px
		    }
		    td {
		        border: none;
		        border-bottom: 1px solid #eee;
		        position: relative;
		        padding-left: 50%
		    }
		    td:before {
		        position: absolute;
		        top: 6px;
		        left: 6px;
		        padding-right: 10px;
		        white-space: nowrap
		    }
		    td:nth-of-type(1):before {
		        content: ""
		    }
		    td:nth-of-type(2):before {
		        content: "Laina"
		    }
		    td:nth-of-type(3):before {
		        content: "Maksu"
		    }
		    td:nth-of-type(4):before {
		        content: "Kulut"
		    }
		    td:nth-of-type(5):before {
		        content: "Er\00e4t"
		    }
		    td:nth-of-type(6):before {
		        content: "Laina-aika"
		    }
		    td:nth-of-type(7):before {
		        content: "Ik\00e4raja"
		    }
		    td:nth-of-type(8):before {
		        content: " "
		    }

		    tr.no-results {
		    	td {
		    		padding-left: 0;
		    	}
		    }
	    }	    
	}    
}

/**
 * adding sorting ability to HTML tables with Bootstrap styling
 * @summary HTML tables sorting ability
 * @version 2.0.0
 * @requires tinysort, moment.js, jQuery
 * @license MIT
 * @author Matus Brlit (drvic10k)
 * @copyright Matus Brlit (drvic10k), bootstrap-sortable contributors
 */

table.sortable span.sign {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 12px;
    margin-top: -10px;
    color: #bfbfc1;
}

table.sortable th:after {
    display: block;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 12px;
    margin-top: -10px;
    color: #bfbfc1;
}

table.sortable th.arrow:after {
    content: '';
}

table.sortable span.arrow, span.reversed, th.arrow.down:after, th.reversedarrow.down:after, th.arrow.up:after, th.reversedarrow.up:after {
    border-style: solid;
    border-width: 5px;
    font-size: 0;
    border-color: #ccc transparent transparent transparent;
    line-height: 0;
    height: 0;
    width: 0;
    margin-top: -2px;
}

    table.sortable span.arrow.up, th.arrow.up:after {
        border-color: transparent transparent #ccc transparent;
        margin-top: -7px;
    }

table.sortable span.reversed, th.reversedarrow.down:after {
    border-color: transparent transparent #ccc transparent;
    margin-top: -7px;
}

    table.sortable span.reversed.up, th.reversedarrow.up:after {
        border-color: #ccc transparent transparent transparent;
        margin-top: -2px;
    }

table.sortable span.az:before, th.az.down:after {
    content: "a .. z";
}

table.sortable span.az.up:before, th.az.up:after {
    content: "z .. a";
}

table.sortable th.az.nosort:after, th.AZ.nosort:after, th._19.nosort:after, th.month.nosort:after {
    content: "..";
}

table.sortable span.AZ:before, th.AZ.down:after {
    content: "A .. Z";
}

table.sortable span.AZ.up:before, th.AZ.up:after {
    content: "Z .. A";
}

table.sortable span._19:before, th._19.down:after {
    content: "1 .. 9";
}

table.sortable span._19.up:before, th._19.up:after {
    content: "9 .. 1";
}

table.sortable span.month:before, th.month.down:after {
    content: "jan .. dec";
}

table.sortable span.month.up:before, th.month.up:after {
    content: "dec .. jan";
}

table.sortable>thead th:not([data-defaultsort=disabled]) {
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
}

table.sortable>thead th:hover:not([data-defaultsort=disabled]) {
    background: #efefef;
}

table.sortable>thead th div.mozilla {
    position: relative;
}